import React, { useEffect } from "react";
import Header from "../../common/Header";
import { Box, Typography, Grid, useMediaQuery } from "@mui/material";
import { useState } from "react";
// import "../../styles/globalStyles.css";
import AlertMessage from "../../common/AlertMessage";
// import "../../styles/Register.css";
import Footer from "../../common/Footer";
// import TextBanner from "../../images/Home/Text Banner.png";
// import RegisterBanner from "../../images/Register Banner.svg";
// import MidBanner2 from "../../images/testBanner.svg";
import { Token } from "../../common/Credential/credential";
import theme from "../../theme";
import { Helmet } from "react-helmet";
import clevertap from "clevertap-web-sdk";
// import { google } from 'googleapis';

// Set up the Google Sheets API client using your credentials
// const sheets = google.sheets('v4');
// const sheetsAuth = new google.auth.GoogleAuth({
//     keyFile: '../../../googleSheetSecret.json',
//     scopes: ['https://www.googleapis.com/auth/spreadsheets'],
// });

const Register = () => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const windowWidth = document.documentElement.clientWidth;

  if (/(iPhone|iPad)/i.test(navigator.userAgent)) {
    document.documentElement.className += " is-iphone";
  } else if (/(Android)/i.test(navigator.userAgent)) {
    document.documentElement.className += " is-android";
  }

  // Function to update the screen height
  const updateScreenHeight = () => {
    setScreenHeight(window.innerHeight);
    console.log("window:", window.innerHeight);
  };

  useEffect(() => {
    updateScreenHeight();
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  useEffect(() => {
    //GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "pageview",
      event_action: "view",
      name: "registration_page",
    });
  }, []);

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "+91-",
    email: "",
    city: "",
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleTextInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const isValid = (data) => {
    if (
      data.name.trim() !== "" &&
      data.primary_email.trim() !== "" &&
      data.city.trim() !== "" &&
      data.primary_phoneNo.trim !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  // mobile no format +91-XXXXXXXXXX
  const isMobileNoValid = (mobileNumber) => {
    const pattern = /^\+91-\d{10}$/;
    if (!pattern.test(mobileNumber)) {
      setSnackbar({
        open: true,
        message: "Mobile No should be in +91-XXXXXXXXXX Format",
        severity: "error",
      });
      return false;
    } else {
      return true;
    }
  };

  const handleRegisterNow = async (e) => {
    e.preventDefault();

    //API
    const data = {
      name: formValues?.name,
      primary_phoneNo: formValues?.phone,
      primary_email: formValues?.email,
      city: formValues?.city,
      i_agree_to_the_terms_and_conditions_: "true",
    };

    if (isValid(data)) {
      if (isMobileNoValid(data.primary_phoneNo)) {
        try {
          const myHeaders = new Headers();
          // myHeaders.append("Authorization", "Bearer " + Token);
          myHeaders.append("Content-Type", "application/json");
          const formData = JSON.stringify(data);
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
            redirect: "follow",
          };

          /*fetch(
                        "https://regsystem.bookmyshow.com/usersubmission/ET00370953?formType=Presales",
                        requestOptions
                    ) */

          fetch(
            "https://owners.brikitt.com/api/v1/bandland/user/",
            requestOptions
          )
            .then((res) => res.json())
            .then((response) => {
              console.log("response:", response);
              if (response) {
                setSnackbar({
                  open: true,
                  message: "Rock on, partner. Welcome to the fold!",
                  severity: "success",
                });
                setFormValues({
                  name: "",
                  phone: "+91-",
                  email: "",
                  city: "",
                });
              } else {
                setSnackbar({
                  open: true,
                  message: "Uh-oh. Could you try that again?",
                  severity: "error",
                });
              }
            });
        } catch (error) {
          setSnackbar({
            open: true,
            message: "Uh-oh. Could you try that again?",
            severity: "error",
          });
        }
      }
    } else {
      setSnackbar({
        open: true,
        message: "All Fields are required!",
        severity: "error",
      });
    }
    // Get the values from the form

    // // save data to sheet
    // const name = formValues?.name;
    // const phone = formValues?.phone;
    // const email = formValues?.email;
    // const city = formValues?.city;

    // // Create the data to be added to the Google Sheet
    // const rowData = [name, phone, email, city];

    // if (isValid(data)) {
    //     if (isMobileNoValid(data.primary_phoneNo)) {
    //         try {
    //             // Authorize and create a connection to the Google Sheets API
    //             const auth = await sheetsAuth.getClient();
    //             const sheetsAPI = google.sheets({ version: 'v4', auth });

    //             // Define the spreadsheet ID and range
    //             const spreadsheetId = '18ir2S9KbnvsO8ks82GIS2yHSUaIs55PfJ7R8czixlnU';
    //             const range = 'A2:D2'; // Adjust the range as needed

    //             // Append the data to the Google Sheet
    //             const response = await sheetsAPI.spreadsheets.values.append({
    //                 spreadsheetId,
    //                 range,
    //                 valueInputOption: 'RAW',
    //                 resource: {
    //                     values: [rowData],
    //                 },
    //             });

    //             setSnackbar({
    //                 open: true,
    //                 message: "You response has be recorded successfully!",
    //                 severity: "success",
    //             });
    //             setFormValues({
    //                 name: "",
    //                 phone: "+91-",
    //                 email: "",
    //                 city: "",
    //             });
    //         } catch (error) {
    //             console.error('Error adding data to Google Sheet:', error);
    //             setSnackbar({
    //                 open: true,
    //                 message: "Something went wrong!",
    //                 severity: "error",
    //             });
    //         }
    //     }
    // } else {
    //     setSnackbar({
    //         open: true,
    //         message: "All Fields are required!",
    //         severity: "error",
    //     });
    // }
    // GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "click",
      event_action: "registration_submit",
    });

    // clevertap
    clevertap.event.push("Bandland Register Submit", {
      name: formValues?.name,
      email: formValues?.email,
      phoneno: formValues?.phone,
      city: formValues?.city,
    });
  };

  const pageContent = (
    <Box
      position="absolute"
      sx={{
        marginTop: {
          xs: "22%",
          sm: "25%",
          md: "22%",
          lg: "22%",
          xl: "22%",
          xll: "22%",
        },
      }}
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      width="100%"
    >
      <Box
        sx={{
          mt: { xs: "30%", sm: "25%", md: "25%", lg: "25%" },
          mb: { xs: "-100px", sm: "-120px", md: "-200px", lg: "-330px" },
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "12px",
              sm: "14px",
              md: "18px",
              lg: "20px",
              xl: "20px",
              xll: "25px",
            },
            // mt: { xs: 10, sm: 10, lg: 10 },
          }}
          color={"white"}
          fontFamily="texturina"
          fontWeight="bold"
        >
          EMBASSY INTERNATIONAL <br /> RIDING SCHOOL
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: {
            xs: "40px",
            sm: "40px",
            md: "55px",
            lg: "70px",
            xl: "80px",
            xll: "100px",
          },
          mt: { xs: 13, sm: 20, md: 30, lg: 40, xl: 50, xll: 60 },
          zIndex: 2,
        }}
        className="text-shadow text-stroke font-modesto-condensed"
        color={"white"}
        width={{
          xs: "70%",
          sm: "90%",
          md: "60%",
          lg: "60%",
          xl: "60%",
          xll: "60%",
        }}
      >
        PLEDGE ALLEGIANCE TO THE BAND!
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "14px",
            md: "22px",
            lg: "30px",
            xl: "35px",
            xll: "35px",
          },
        }}
        fontFamily="texturina"
        className="font-texturina typographyTextSecond"
        color={"white"}
        width={{
          xs: "65%",
          sm: "50%",
          md: "50%",
          lg: "50%",
          xl: "50%",
          xll: "52%",
        }}
      >
        Join the inner circle to receive all festival updates, from artist
        lineup to tickets, schedule, guides & more. This one’s for you!
      </Typography>
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex="4"
        height={
          windowWidth <= 360
            ? "330px"
            : windowWidth <= 480
            ? "420px"
            : windowWidth <= 700
            ? "650px"
            : windowWidth <= 900
            ? "540px"
            : windowWidth <= 1300
            ? "580px"
            : "650px"
        }
        sx={{
          width: { xs: "85%", sm: "75%", md: "60%", lg: "50%", xl: "50%" },
          // height: {
          //   xs: "530px",
          //   sm: "900px",
          //   md: "700px",
          //   lg:
          //     screenHeight <= 900
          //       ? "85vh"
          //       : screenHeight <= 1100
          //       ? "70vh"
          //       : "60vh",
          //   xl: "85vh",
          //   xll:
          //     screenHeight <= 780
          //       ? "100vh"
          //       : screenHeight <= 900
          //       ? "95vh"
          //       : "76vh",
          // },
        }}
      >
        {/* <Box border="2px solid blue"> */}
        {isSmallScreen ? (
          <img
            src="https://bandland.s3.ap-south-1.amazonaws.com/images/Home/Text+Banner.png"
            alt="img"
            width="80%"
            height={windowWidth <= 1024 ? 480 : "80%"}
          />
        ) : (
          <img
            src="https://bandland.s3.ap-south-1.amazonaws.com/images/Register+Banner.svg"
            alt="img"
            width={windowWidth <= 360 ? "95%" : "90%"}
          />
        )}
        {/* </Box> */}
        <form
          style={{ position: "absolute", width: "60%" }}
          className="pos-absolute form-width"
          onSubmit={handleRegisterNow}
        >
          <Grid container marginTop="-40px">
            <Grid item xs={5}>
              <Typography
                backgroundColor="white"
                fontFamily="texturina"
                display="flex"
                border="2px solid"
                // paddingLeft="10px"
                className="form-font"
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: windowWidth < 650 ? "12px" : "18px",
                    md: "22px",
                    lg: "25px",
                    xl: "25px",
                    xll: "25px",
                  },
                  padding: {
                    xs: "4px",
                    sm: "4px 6px",
                    md: "2px 8px",
                    lg: "0px 10px",
                    xl: "0px 10px",
                    xll: "0px 12px",
                  },
                }}
              >
                Name:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <input
                style={{
                  width: "100%",
                  border: "2px solid",
                  outline: "none",
                  borderRadius: 0,
                }}
                size="small"
                value={formValues?.name}
                name="name"
                onChange={handleTextInputChange}
                className="register-input"
              />
            </Grid>
            <Grid
              item
              xs={5}
              sx={{ mt: { xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xll: 4 } }}
            >
              <Typography
                backgroundColor="white"
                fontFamily="texturina"
                display="flex"
                border="2px solid"
                // paddingLeft="10px"
                className="form-font"
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: windowWidth < 650 ? "12px" : "18px",
                    md: "22px",
                    lg: "25px",
                    xl: "25px",
                    xll: "25px",
                  },
                  padding: {
                    xs: "4px",
                    sm: "4px 6px",
                    md: "2px 8px",
                    lg: "0px 10px",
                    xl: "0px 10px",
                    xll: "0px 12px",
                  },
                }}
              >
                Phone No:
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              sx={{ mt: { xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xll: 4 } }}
            >
              <input
                style={{
                  width: "100%",
                  border: "2px solid",
                  outline: "none",
                  borderRadius: 0,
                }}
                value={formValues?.phone}
                name="phone"
                className="register-input"
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid
              item
              xs={5}
              sx={{ mt: { xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xll: 4 } }}
            >
              <Typography
                backgroundColor="white"
                fontFamily="texturina"
                display="flex"
                border="2px solid"
                // paddingLeft="10px"
                className="form-font"
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: windowWidth < 650 ? "12px" : "18px",
                    md: "22px",
                    lg: "25px",
                    xl: "25px",
                    xll: "25px",
                  },
                  padding: {
                    xs: "4px",
                    sm: "4px 6px ",
                    md: "2px 8px",
                    lg: "0px 10px",
                    xl: "0px 10px",
                    xll: "0px 12px",
                  },
                }}
              >
                Email ID:
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              sx={{ mt: { xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xll: 4 } }}
            >
              <input
                style={{
                  width: "100%",
                  border: "2px solid",
                  outline: "none",
                  borderRadius: 0,
                }}
                value={formValues?.email}
                name="email"
                type="email"
                className="register-input"
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid
              item
              xs={5}
              sx={{ mt: { xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xll: 4 } }}
            >
              <Typography
                backgroundColor="white"
                fontFamily="texturina"
                display="flex"
                border="2px solid"
                // paddingLeft="10px"
                className="form-font"
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: windowWidth < 650 ? "12px" : "18px",
                    md: "22px",
                    lg: "25px",
                    xl: "25px",
                    xll: "25px",
                  },
                  padding: {
                    xs: "4px",
                    sm: "4px 6px",
                    md: "2px 8px",
                    lg: "0px 10px",
                    xl: "0px 10px",
                    xll: "0px 12px",
                  },
                }}
              >
                City:
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              sx={{ mt: { xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xll: 4 } }}
            >
              <input
                style={{
                  width: "100%",
                  border: "2px solid",
                  outline: "none",
                  borderRadius: 0,
                }}
                value={formValues?.city}
                name="city"
                onChange={handleTextInputChange}
                className="register-input"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ mt: { xs: 0, sm: 2, md: 2, lg: 2, xl: 2, xll: 2 } }}
              display={"flex"}
              justifyContent={"center"}
            >
              <button
                className="font-modesto-condensed text-stroke register-signup-button"
                style={{
                  backgroundColor: "#44C0A8",
                  // fontSize: "40px",
                  color: "#fffde7",
                  marginTop: "12px",
                  cursor: "pointer",
                  // padding:"2px 25px"
                }}
                type="submit"
              >
                {" "}
                Register Now{" "}
              </button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box
        position="relative"
        bottom="0"
        sx={{
          mt: {
            xs: -2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
            xll: 2,
            xxl:
              screenHeight <= 900
                ? "100vh"
                : screenHeight <= 1100
                ? "90vh"
                : screenHeight <= 1400
                ? "75vh"
                : 60,
          },
        }}
      >
        <Footer />
      </Box>

      <AlertMessage snackbar={snackbar} setSnackbar={setSnackbar} />
    </Box>
  );

  return (
    <>
      <Helmet>
        <title>Rock's One True Homecoming. Back to Bangalore!</title>
        <meta
          name="description"
          content="Bandland 2023 is a rock festival in Bangalore, India. It will be a 2-day event that will relive the Golden Era of rock music. Register now to secure your spot!"
        />
        <meta
          name="keywords"
          content="Bandland, Bandland Bangalore, Bandland 2023, Bandland 2023 tickets, Bandland tickets, Bandland meaning, Bandland festival, Bandland registration"
        />
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NNK87PBS');
                    `}
        </script>
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NNK87PBS"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      </Helmet>
      <Header pageContent={pageContent} />
    </>
  );
};

export default Register;
