import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
// import "../src/styles/Home.css";
import { useEffect, useRef, useState, lazy, Suspense } from "react";
import Home from "./pages/home";
import Register from "./pages/register";
import About from "./pages/about";
import Artists from "./pages/artist";
import Tickets from "./pages/tickets";
import FAQ from "./pages/faq";
import ReactGA from 'react-ga';
import { YOUR_TRACKING_ID } from "./common/Credential/credential";
// import HomeBg from "./images/BG/BG for Phase 2_Tickets_Desktop.png"
// import RegisterBg from "./images/BG/BG Phase 1_Register Page_Desktop.png"
// import AboutBg from "./images/BG/BG Phase 2_About_Bandland_Desktop – 1.png"
// import ArtistBg from "./images/BG/BG Phase 2_Artists_Desktop.png"
// import TicketBg from "./images/BG/BG for Phase 2_Tickets_Desktop.png"
// import FAQBg from "./images/BG/BG Phase 2_FAQ_Desktop.png"
import { Box, Paper, useMediaQuery } from "@mui/material";
import theme from "./theme";
// import HeaderBase from "./images/Home/SideBorder.svg";


function App() {
  // const [bgImage, setBgImage] = useState(HomeBg);
  const { pathname: currentLocation } = useLocation();
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));


  // add your tracking id here
  ReactGA.initialize(YOUR_TRACKING_ID);

  // useEffect(() => {
  //   switch (currentLocation) {
  //     case "/":
  //       setBgImage(HomeBg);
  //       break;
  //     case "/register":
  //       setBgImage(RegisterBg);
  //       break;
  //     case "/about":
  //       setBgImage(AboutBg);
  //       break;
  //     case "/tickets":
  //       setBgImage(TicketBg);
  //       break;
  //     case "/faq":
  //       setBgImage(FAQBg);
  //       break;
  //     case "/the-artists":
  //       setBgImage(ArtistBg);
  //       break;
  //     default:
  //       setBgImage(HomeBg); // Default background for unknown routes
  //   }
  // }, [currentLocation]);

  // const styles = {
  //   paperContainer: {
  //     backgroundImage: `url(${bgImage})`,
  //     backgroundSize: "cover",
  //     backgroundRepeat: "no-repeat",
  //     backgroundAttachment: "fixed", 
  //     minHeight: "100vh",
  //   }
  // };

  const routeToBackgroundClass = {
    "/": isSmallScreen ? "background-home" : "background-home-mobile",
    "/register": isSmallScreen ? "background-register" : "background-register-mobile",
    "/about": isSmallScreen ? "background-about" : "background-about-mobile",
    "/the-artists": isSmallScreen ? "background-artists":"background-artists-mobile",
    "/tickets": isSmallScreen ? "background-tickets":"background-tickets-mobile",
    "/faq": isSmallScreen ? "background-faq":"background-faq-mobile",
  };

  const backgroundClass = routeToBackgroundClass[currentLocation] || "background-default";

  // Calculate the content height dynamically
  // useEffect(() => {
  //   if (contentRef.current) {
  //     const newHeight = contentRef.current.clientHeight;
  //     setContentHeight(newHeight);
  //   }
  // }, [currentLocation]);

  // apply to Paper --> className={`background-paper ${backgroundClass}`}  style={{ minHeight: contentHeight }}


  useEffect(() => {
    // Add a class to the body element based on the current route
    document.body.className = `background-paper ${backgroundClass}`;
  }, [currentLocation]);
  

  return (
    <Box>
      {/* <img
        className="w-100px"
        src={bgImage}
        alt="img"
        // style={{ objectFit: 'cover', width: "110%" }}
      /> */}
      <div ref={contentRef}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/the-artists" element={<Artists />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/faq" element={<FAQ />} /> */}
        </Routes>
      </div>
    </Box>
  );
}

export default App;
