import * as React from "react";
// import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { sliderImagesMobile, sliderImagesWeb } from "../../common/sliderImage";
import "./home.css";
import Slider from "react-slick";

const windowHeight = document.documentElement.clientHeight;

const style = {
  position: "absolute",
  top: { xs: "50%", sm: "50%", md: "30%", lg: "20%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: { xs: "40vh", sm: "80vh", md: "50vh", lg: "20vh" },
  width: {
    xs: windowHeight < 450 ? "40%" : "80%",
    sm: windowHeight < 550 ? "40%" : "70%",
    md: "60%",
    lg: "50%",
  },
  cursor: "pointer",
};

export default function SliderPopup({ setOpen, open, image }) {
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.up("xs"));
  const images = isExtraSmallScreen
    ? [...sliderImagesMobile]
    : [...sliderImagesWeb];
  const newSliderImage = images.filter((item) => item !== image);
  newSliderImage.unshift(image);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <Slider {...settings} style={{ cursor: "pointer" }}>
            {newSliderImage?.map((image) => {
              return (
                <img
                  src={image}
                  alt="img"
                  className="sliderCarouselImage"
                  onClick={() => setOpen(false)}
                />
              );
            })}
          </Slider>
        </Box>
      </Modal>
    </div>
  );
}
