import React, { useEffect, useState } from "react";
// import "../../styles/Home.css";
// import TextBanner from "../../images/Home/Text Banner.png";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
// import OrangeBand from "../../images/Home/Orange Band2.png";
// import Footer from "../../common/Footer";
import Header from "../../common/Header";
// import Banner from "../../images/testBanner.svg";
// import gogoDollBanner from "../../images/gogoDollBanner.svg";
// import greenside from "../../images/side green vines .svg";
import Footer from "../../common/Footer/index";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Helmet } from "react-helmet";
import clevertap from "clevertap-web-sdk";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
import { sliderImagesMobile, sliderImagesWeb } from "../../common/sliderImage";
import SliderPopup from "./SliderPopup";
import VideoModal from "./VideoModal";

const Home = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const windowWidth = document.documentElement.clientWidth;
  const windowHeight = document.documentElement.clientHeight;
  console.log("windowWidth", windowWidth);
  console.log("windowHeight", windowHeight);
  const navigate = useNavigate();
  const theme = useTheme();
  const [modalImage, setModalImage] = useState("");

  // to handle slider images popup
  const [open, setOpen] = React.useState(false);

  // to handle video popup
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  // console.log('theme:', theme.globalStyles.headerImage)
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isScreenLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isScreenXL = useMediaQuery(theme.breakpoints.up("xl"));
  const isScreenXLL = useMediaQuery(theme.breakpoints.up("xll"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.up("xs"));

  useEffect(() => {
    //GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "pageview",
      event_action: "view",
      name: "home_page",
    });
    //clevertap
    clevertap.event.push("Bandland PageView");
  }, []);
  // const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  // // Function to update the screen height
  // const updateScreenHeight = () => {
  //     setScreenHeight(window.innerHeight);
  //     console.log('window:', window.innerHeight)
  // };

  //     const [headerImagesLoaded, setHeaderImagesLoaded] = useState(false);

  //   const handleHeaderImagesLoad = () => {
  //     setHeaderImagesLoaded(true);
  //   };

  const pageContent = (
    <Box
      position="absolute"
      sx={{
        marginTop: {
          xs: "40%",
          sm: "40%",
          md: "40%",
          lg: "40%",
          xl: "40%",
          xll: "40%",
        },
      }}
      width="100%"
    >
      {/* To show gogoDollBanner uncommet below code & set top="20%" on above Box */}
      {/* https://bandland.s3.ap-south-1.amazonaws.com/artists/gogoDollBanner+(2)+(1).png */}
      {/* <Box > */}
      {/* {isSmallScreen ? <img src="https://bandland.s3.ap-south-1.amazonaws.com/home_gogoDollbanner/SVG/text+as+font/Lineup+for+Website+SVG+1.svg" className="responsive-image2" alt="img" style={{
                // width: "60%",
                height: "auto",
                objectFit: 'cover',
                top: 0,
                zIndex: 1,
                // marginLeft: "-1%",
                // marginTop: "-5%",
            }} /> : <img src="https://bandland.s3.ap-south-1.amazonaws.com/home_gogoDollbanner/1x/Lineup+for+Website.webp" className="responsive-image2" alt="img" style={{
                // width: "60%",
                height: "auto",
                objectFit: 'cover',
                top: 0,
                zIndex: 1,
                // marginLeft: "-1%",
                // marginTop: "-5%",
            }} />} */}
      {/* </Box> */}
      <Box marginTop="5%">
        <Typography
          sx={{
            fontSize: {
              xs: "12px",
              sm: "14px",
              md: "18px",
              lg: "20px",
              xl: "20px",
              xll: "25px",
            },
            mt: { xs: windowWidth < 480 ? 10 : 13, sm: 14, md: 8, lg: 9 },
          }}
          color={"white"}
          fontFamily="texturina"
          fontWeight="bold"
        >
          EMBASSY INTERNATIONAL <br /> RIDING SCHOOL
        </Typography>
      </Box>

      <Typography
        sx={{
          mt: { xs: 2, sm: 1, lg: 5 },
          fontSize: {
            xs: "30px",
            sm: "45px",
            md: "50px",
            lg: "70px",
            xl: "90px",
          },
          lineHeight: { xs: "35px", sm: "50px", md: "65px", lg: "80px" },
        }}
        fontFamily="modesto-condensed"
        color="white"
        style={{
          textShadow: "3px 3px 0px #390c06",
          textAlign: "center",
        }}
      >
        BANDLAND FOREVER <br /> THE BANGALORE STORY
      </Typography>

      {/* <h2 className="video-title">
        BANDLAND FOREVER <br /> THE BANGALORE STORY
      </h2> */}

      {/* video start */}
      <Box
        sx={{ mt: { xs: 2, sm: 1, md: 2, lg: 2 } }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className="pos-relative flex-center flex-col align-center"
      >
        <img
          className="responsive-video-banner"
          src="https://bandland.s3.ap-south-1.amazonaws.com/images/ticketTextBanner3.png"
          alt="img"
        />
        <Box
          className="video-container"
          zIndex={4}
          position="absolute"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* video modal */}
          <VideoModal
            videoModalOpen={videoModalOpen}
            videoModalClose={() => setVideoModalOpen(false)}
          />

          <div
            className="video-wrapper"
            onClick={() => setVideoModalOpen(true)}
          >
            <iframe
              autoplay={false}
              aria-disabled={true}
              style={{ pointerEvents: "none" }}
              title="dfsf"
              width={
                windowWidth <= 340
                  ? "150"
                  : windowWidth <= 390
                  ? "200"
                  : windowWidth <= 450
                  ? "200"
                  : windowWidth <= 600
                  ? "270"
                  : windowWidth <= 940
                  ? "230"
                  : windowWidth <= 1024
                  ? "300"
                  : windowWidth <= 1100
                  ? "350"
                  : windowWidth <= 1300
                  ? "400"
                  : windowWidth <= 1600
                  ? "530"
                  : windowWidth <= 1800
                  ? "600"
                  : windowWidth <= 2000
                  ? "650"
                  : "750"
              }
              height={
                windowWidth <= 330
                  ? "100"
                  : windowWidth <= 380
                  ? "100"
                  : windowWidth <= 420
                  ? "120"
                  : windowWidth <= 450
                  ? "120"
                  : windowWidth <= 530
                  ? "130"
                  : windowWidth <= 600
                  ? "160"
                  : windowWidth <= 700
                  ? "120"
                  : windowWidth <= 850
                  ? "150"
                  : windowWidth <= 940
                  ? "150"
                  : windowWidth <= 1024
                  ? "200"
                  : windowWidth <= 1100
                  ? "220"
                  : windowWidth <= 1300
                  ? "250"
                  : windowWidth <= 1450
                  ? "280"
                  : windowWidth <= 1700
                  ? "300"
                  : windowWidth <= 1900
                  ? "350"
                  : windowWidth <= 1250
                  ? "350"
                  : windowWidth <= 1450
                  ? "350"
                  : "400"
              }
              src="https://www.youtube.com/embed/yhYG9NCbeNA?disablekb=1&controls=0"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </Box>
      </Box>
      {/* video end */}

      {/* <Typography
            sx={{ fontSize: { xs:"35px", sm:"55px", md:"65px", lg:"80px", xl:"90px" } }}
            color={"white"}
            fontFamily='modesto-condensed'
            style={{ textShadow: "3px 3px 0px #390c06" }}
        >
            ENTER BANDLAND
        </Typography>

        <Box position="relative" display="flex" justifyContent="center" flexDirection="column" className="pos-relative flex-center flex-col align-center textBannerBox">
            <img
                src="https://bandland.s3.ap-south-1.amazonaws.com/images/Home/Text+Banner.png"
                alt="img"
                className="responsive-text-banner"
            />
            <Typography fontFamily="texturina" position="absolute" width="45%" sx={{ fontSize: { xs: "11px", sm: "16px", md: "18px", lg: "20px", xl: "24px", xll: "26px", xxl: "30px" }, top:{sx:0}}} marginTop="-20px" >
                Welcome to the state of Bandland. It doesn’t matter who you are or
                where you’re from. All that matters is that you belong. Sling that
                six-string & pledge allegiance to the band. It’s rock’s one true
                homecoming, back to Bangalore. Trends may come & go, but Bandland is
                forever.
            </Typography>
        </Box> */}
      <Box
        sx={{ mt: { xs: 3, sm: 5, lg: 10 } }}
        position="relative"
        className="pos-relative"
        style={{ width: "100%" }}
        zIndex="-1"
      >
        <Box width="100%">
          {isMediumScreen ? (
            <img
              style={{ objectFit: "cover" }}
              src="https://bandland.s3.ap-south-1.amazonaws.com/images/Home/Orange+Band2.png"
              alt="img"
              width="100%"
              height={
                windowWidth <= 1200
                  ? 380
                  : windowWidth <= 1280
                  ? 550
                  : windowWidth <= 1599
                  ? 550
                  : windowWidth <= 1919
                  ? 600
                  : 700
              }
            />
          ) : (
            <img
              style={{ objectFit: "cover" }}
              src="https://bandland.s3.ap-south-1.amazonaws.com/images/Home/Orange+Band2.png"
              alt="img"
              width="100%"
              height={windowWidth <= 600 ? 265 : 300}
            />
          )}
        </Box>
        {isScreenXLL || isScreenXL || isScreenLg || isMediumScreen ? (
          <Box width="100%" position="absolute" top="0">
            <img
              style={{ objectFit: "cover" }}
              src="https://bandland.s3.ap-south-1.amazonaws.com/images/side+green+vines+.svg"
              // className="orange-banner"
              alt="img"
              width="95%"
              height="110%"
            />
          </Box>
        ) : (
          <></>
        )}
        <Box className="responsive-image two-days-stage">
          <Grid
            className="desc"
            container
            spacing={2}
            padding="0"
            display={{ xs: "flex" }}
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "center" }}
            alignItems={{ xs: "center" }}
          >
            <Grid
              item
              xs={6}
              sm={5}
              display={{ sm: "flex" }}
              sx={{
                mt: {
                  xs: "-27px",
                  sm: "-10px",
                  md: "-50px",
                  lg: "-25px",
                  xl: "-65px",
                },
              }}
              justifyContent={{ sm: "end" }}
            >
              <Typography
                fontFamily="modesto-open-caps"
                color="white"
                style={{ textShadow: "3px 3px 0px #390c06" }}
                sx={{
                  fontSize: {
                    xs: "90px",
                    sm: "120px",
                    md: "180px",
                    lg: "240px",
                    xl: "290px",
                    xll: "320px",
                  },
                }}
                className="midDesc1"
              >
                2
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={7}
              display={{
                xs: "flex",
                sm: "grid",
                md: "grid",
                lg: "grid",
                xl: "grid",
              }}
              justifyContent={{ xs: "center", sm: "start" }}
            >
              <Typography
                sx={{
                  mt: { xs: -6, sm: -3, md: -3, lg: -4, xl: -6, xll: -6 },
                  fontSize: {
                    xs: "30px",
                    sm: "45px",
                    md: "65px",
                    lg: "80px",
                    xl: "85px",
                    xll: "95px",
                  },
                }}
                display={{ sm: "flex" }}
                fontFamily="modesto-condensed"
                color="white"
                style={{ textShadow: "3px 3px 0px #390c06" }}
                className="midDesc2"
              >
                DAYS.
              </Typography>
              <Typography
                sx={{
                  mt: { xs: -6, sm: -3, md: -3, lg: -3, xl: -4, xll: -4 },
                  fontSize: {
                    xs: "30px",
                    sm: "45px",
                    md: "65px",
                    lg: "80px",
                    xl: "85px",
                    xll: "95px",
                  },
                }}
                fontFamily="modesto-condensed"
                color="white"
                style={{ textShadow: "3px 3px 0px #390c06" }}
                className="midDesc2"
              >
                STAGES.
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              sm={4.5}
              sx={{
                mt: { xs: -6, sm: -8, md: -14, lg: -18, xl: -20 },
                ml: { sm: -4, md: -6, lg: -8, xl: -10 },
              }}
              display={{ sm: "flex" }}
              justifyContent={{ sm: "end" }}
            >
              <Typography
                fontFamily="modesto-open-caps"
                color="white"
                style={{ textShadow: "3px 3px 0px #390c06" }}
                sx={{
                  fontSize: {
                    xs: "80px",
                    sm: "120px",
                    md: "180px",
                    lg: "240px",
                    xl: "290px",
                    xll: "320px",
                  },
                }}
                className="midDesc1"
              >
                12
              </Typography>
            </Grid>
            <Grid
              item
              // xs={6}
              sm={7.5}
              display={{ sm: "grid" }}
              justifyContent={{ xs: "center", sm: "start" }}
            >
              <Typography
                sx={{
                  mt: { xs: -6, sm: -8, md: -12, lg: -16, xl: -17, xll: -19 },
                  fontSize: {
                    xs: "30px",
                    sm: "45px",
                    md: "65px",
                    lg: "75px",
                    xl: "85px",
                    xll: "95px",
                  },
                }}
                display={{ sm: "flex" }}
                fontFamily="modesto-condensed"
                color="white"
                style={{ textShadow: "3px 3px 0px #390c06" }}
                className="midDesc2"
              >
                POWER PACKED
              </Typography>

              <Typography
                sx={{
                  mt: { xs: -2, sm: -2, md: -2, lg: -4, xl: -4, xll: -5 },
                  fontSize: {
                    xs: "30px",
                    sm: "45px",
                    md: "65px",
                    lg: "75px",
                    xl: "85px",
                    xll: "95px",
                  },
                }}
                fontFamily="modesto-condensed"
                color="white"
                style={{ textShadow: "3px 3px 0px #390c06" }}
                className="midDesc2"
              >
                PERFORMANCES
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        sx={{ mt: { xs: 2, lg: 10 } }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className="pos-relative flex-center flex-col align-center"
        // marginBottom="10%"
      >
        <img
          className="responsive-video-banner"
          src="https://bandland.s3.ap-south-1.amazonaws.com/images/ticketTextBanner2.png"
          alt="img"
        />
        <Box
          sx={{
            mt: { xs: 2, sm: 2, md: 2, lg: 4, xl: 4, xll: 5 },
            width: { xs: "65%", sm: "42%", md: "40%", lg: "40%", xl: "40%" },
          }}
          position="absolute"
          // width="45%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          zIndex={4}
        >
          <div className="sliderWrapper">
            <SliderPopup image={modalImage} setOpen={setOpen} open={open} />
            <Slider {...settings} style={{ cursor: "pointer" }}>
              {(isExtraSmallScreen ? sliderImagesMobile : sliderImagesWeb)?.map(
                (image) => {
                  return (
                    <img
                      src={image}
                      alt="img"
                      className="sliderImage"
                      onClick={() => {
                        setModalImage(image);
                        setOpen(true);
                      }}
                    />
                  );
                }
              )}
            </Slider>
          </div>
        </Box>
      </Box>

      <Box
        sx={{ mt: { xs: 1, lg: 6 } }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className="pos-relative flex-center flex-col align-center"
        // marginBottom="10%"
      >
        <img
          className="responsive-see-you-again-banner"
          src={
            isExtraSmallScreen
              ? "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/see_you_again_web.webp"
              : "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/see_you_again_mobile.webp"
          }
          alt="img"
        />
        <Box
          fontFamily="texturina"
          position="absolute"
          width="45%"
          sx={{
            fontSize: {
              xs: "11px",
              sm: "16px",
              md: "18px",
              lg: "20px",
              xl: "24px",
              xll: "26px",
            },
          }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginTop="-20px"
        ></Box>
      </Box>

      <Typography
        sx={{
          mt: { xs: 1, lg: 10 },
          fontSize: {
            xs: "30px",
            sm: "45px",
            md: "65px",
            lg: "80px",
            xl: "90px",
          },
          // width:{ xs: '150px', sm: '800px' },
        }}
        fontFamily="modesto-condensed"
        color="white"
        style={{
          textShadow: "3px 3px 0px #390c06",
          textAlign: "center",
          margin: "auto",
        }}
        // className="font-modesto-condensed text-shadow heading-relative z-index-2"
      >
        BE A CITIZEN OF {windowWidth <= 480 && <br />} BANDLAND!
      </Typography>
      <Box
        sx={{ mt: { xs: 1, lg: 2 } }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className="pos-relative flex-center flex-col align-center"
        // marginBottom="10%"
      >
        <img
          // className="responsive-text-banner"
          src="https://bandland.s3.ap-south-1.amazonaws.com/images/Home/Text+Banner.png"
          width={"60%"}
          height={
            windowWidth <= 360
              ? 290
              : windowWidth <= 450
              ? 300
              : windowWidth <= 750
              ? 400
              : 320
          }
          alt="img"
        />
        <Box
          fontFamily="texturina"
          position="absolute"
          width="45%"
          sx={{
            fontSize: {
              xs: "11px",
              sm: "16px",
              md: "18px",
              lg: "20px",
              xl: "24px",
              xll: "26px",
            },
          }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginTop="-20px"
        >
          <Typography
            fontFamily="texturina"
            width="100%"
            sx={{
              fontSize: {
                xs: "12px",
                sm: "16px",
                md: "18px",
                lg: "20px",
                xl: "24px",
                xll: "28px",
                xxl: "30px",
              },
            }}
            className=" bannerText font-texturina z-index-3"
          >
            <strong>Pass on the mic. Grab the stand. Take the stage.</strong>
            <br />
            Join the inner circle to receive all festival updates, from artist
            lineup to tickets, schedule, guides & more. This one’s for you!
          </Typography>
          <Button
            variant="contained"
            // fontFamily="modesto-condensed"
            // backgroundColor="#44c0a8"
            style={{
              fontFamily: "modesto-condensed",
              backgroundColor: "#44c0a8",
              cursor: "pointer",
              zIndex: 4,
            }}
            // className="font-modesto-condensed bg-color z-index-3"
            sx={{
              mt: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
              fontSize: { sm: "20px", lg: "20px", xl: "24px" },
            }}
            onClick={() => navigate("/register")}
          >
            {" "}
            Sign Up{" "}
          </Button>
        </Box>
      </Box>
      {/* <Typography
            sx={{ fontSize: { xs: "35px", sm: "55px", md: "65px", lg: "80px", xl: "90px" } }}
            color={"white"}
            fontFamily='modesto-condensed'
            style={{ textShadow: "3px 3px 0px #390c06" }}
            marginTop="20px"
        >
            ENTER BANDLAND
        </Typography>

        <Box position="relative" display="flex" justifyContent="center" flexDirection="column" className="pos-relative flex-center flex-col align-center textBannerBox">
            <img
                src="https://bandland.s3.ap-south-1.amazonaws.com/images/Home/Text+Banner.png"
                alt="img"
                className="responsive-text-banner"
            />
            <Typography fontFamily="texturina" position="absolute" width="45%" sx={{ fontSize: { xs: "11px", sm: "16px", md: "18px", lg: "20px", xl: "24px", xll: "26px", xxl: "30px" }, top: { sx: 0 } }} marginTop="-20px" >
                Welcome to the state of Bandland. It doesn’t matter who you are or
                where you’re from. All that matters is that you belong. Sling that
                six-string & pledge allegiance to the band. It’s rock’s one true
                homecoming, back to Bangalore. Trends may come & go, but Bandland is
                forever.
            </Typography>
        </Box> */}
      <Box position="relative" bottom="0" marginTop="auto">
        <Footer />
      </Box>
    </Box>
  );

  return (
    <Box>
      <Helmet>
        <title>Rock's One True Homecoming. Back to Bangalore!</title>
        <meta
          name="description"
          content="Relive the Golden Era of rock music festivals at the Badland Rock Festival in December 2023. Get your tickets now!"
        />
        <meta
          name="keywords"
          content="Bandland, Bandland 2023, Bandland concert, Bandland party, Bandland Bangalore, Bandland tickets, Bandland 2023, Bandland Bangalore tickets"
        />
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NNK87PBS');
                    `}
        </script>
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NNK87PBS"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      </Helmet>
      <Header
        gogoDollBanner={
          "https://bandland.s3.ap-south-1.amazonaws.com/images/gogoDollBanner.svg"
        }
        pageContent={pageContent}
      />
    </Box>
  );
};

export default Home;
