import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ReactPlayer from "react-player";
import { Clear } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: { xs: "30vh", sm: "40vh", md: "60vh", lg: "80vh" },
  width: { xs: "90%", sm: "80%", md: "90%", lg: "80%" },
  cursor: "pointer",
};

export default function VideoModal({ videoModalOpen, videoModalClose }) {
  return (
    <div>
      <Modal open={videoModalOpen} onClose={() => videoModalClose()}>
        <Box sx={style}>
          <Box sx={{ textAlign: "end" }}>
            <IconButton>
              <Clear
                sx={{
                  color: "black",
                  fontSize: { xs: "30px", sm: "30px", md: "35px", lg: "40px" },
                }}
                onClick={videoModalClose}
              />
            </IconButton>
          </Box>
          <ReactPlayer
            url="https://youtu.be/yhYG9NCbeNA?si=SkPpwTv9BPpIZ4LA"
            width="100%"
            height="100%"
          />
        </Box>
      </Modal>
    </div>
  );
}
