// import sliderImage from "../assets/sliderImage.webp";
export const sliderImagesWeb = [
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Amyl_Bandland22_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/The_War_on_Drugs_Bandland28_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/The_Goo_Goo_Dolls_Bandland47_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/TEB_Bandland3_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/TAAQ_Bandland1_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Skrat_Bandland1_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/People_Vibe_Bandland33_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Parvaaz_Bandland3_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Pacifist_Bandland28_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/HeroShots_Bandland2_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Food_Bandland22_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Experience_Bandland29_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/DeepPurple_Bandland23_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Crowd_Bandland26_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Crowd_Bandland20_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Bars_Beer_Bandland54_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Bars_Beer_Bandland41_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/AWKS_Bandland13_web.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/AquidWaniBranding_BL008_web.webp",
];

export const sliderImagesMobile = [
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Amyl_Bandland22_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/The_War_on_Drugs_Bandland28_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/The_Goo_Goo_Dolls_Bandland47_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/TEB_Bandland3_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/TAAQ_Bandland1_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Skrat_Bandland1_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/People_Vibe_Bandland33_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Parvaaz_Bandland3_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Pacifist_Bandland28_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/HeroShots_Bandland2_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Food_Bandland22_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Experience_Bandland29_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/DeepPurple_Bandland23_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Crowd_Bandland26_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Crowd_Bandland20_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Bars_Beer_Bandland54_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/Bars_Beer_Bandland41_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/AWKS_Bandland13_mobile.webp",
  "https://bandland.s3.ap-south-1.amazonaws.com/last_phase/AquidWaniBranding_BL008_mobile.webp",
];
