import React from "react";
// import "../../styles/Home.css";
// import TextBanner from "../../images/Home/Text Banner.png";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
// import OrangeBand from "../../images/redBand.png";
// import Footer from "../../common/Footer";
import Header from "../../common/Header";
// import Banner from "../../images/testBanner.svg";
// import gogoDollBanner from "../../images/gogoDollBanner.svg";
import Footer from "../../common/Footer/index";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTheme } from "@emotion/react";

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const windowWidth = document.documentElement.clientWidth;
  const windowHeight = document.documentElement.clientHeight;
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const pageContent = (
    <Box position="absolute" top="32%" width="100%" sx={{ marginTop: "50%" }}>
      {/* To show gogoDollBanner uncommet below code & set top="20%" on above Box */}
      {/* <Box >
            <img src={gogoDollBanner} className="responsive-image2" alt="img" style={{
                // width: "60%",
                height: "auto",
                objectFit: 'cover',
                top: 0,
                zIndex:1,
                // marginLeft: "-1%",
                // marginTop: "-5%",
            }} />
        </Box> */}
      <Typography
        sx={{
          fontSize: {
            xs: "35px",
            sm: "55px",
            md: "65px",
            lg: "80px",
            xl: "90px",
          },
          marginTop: { xs: 3 },
        }}
        color={"white"}
        fontFamily="modesto-condensed"
        style={{ textShadow: "3px 3px 0px #390c06" }}
      >
        ENTER BANDLAND
      </Typography>

      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        className="pos-relative flex-center flex-col align-center textBannerBox"
      >
        <img
          src="https://bandland.s3.ap-south-1.amazonaws.com/images/Home/Text+Banner.png"
          alt="img"
          //   className="about-responsive-text-banner"
          width={"60%"}
          height={
            windowWidth <= 600
              ? 300
              : windowWidth <= 1024
              ? 280
              : windowWidth <= 1280
              ? 270
              : windowWidth <= 1440
              ? 320
              : windowWidth <= 1680
              ? 350
              : windowWidth <= 600
              ? 300
              : windowWidth <= 600
              ? 300
              : 350
          }
        />
        <Typography
          fontFamily="texturina"
          position="absolute"
          width="45%"
          sx={{
            fontSize: {
              xs: "11px",
              sm: "16px",
              md: "18px",
              lg: "20px",
              xl: "24px",
              xll: "26px",
            },
            top: { sx: 0 },
          }}
          marginTop="-20px"
        >
          Welcome to the state of Bandland - a two day rock festival that's
          truly one for the fans. Taking place annually in Bangalore, raise your
          horns & be there at the next edition! Trends may come & go, but
          Bandland is forever. See you again in 2024. forever.
        </Typography>
      </Box>
      <Box
        sx={{ mt: { xs: 5, sm: 5, lg: 10 } }}
        position="relative"
        className="pos-relative"
        style={{ width: "100%" }}
        marginBottom="5%"
      >
        <Box position="relative" width="100%" zIndex="-1">
          {/* <img
                    style={{ objectFit: "cover" }}
                    src="https://bandland.s3.ap-south-1.amazonaws.com/images/redBand.png"
                    className="orange-banner"
                    alt="img"
                    width="100%"
                /> */}
          {isMediumScreen ? (
            <img
              style={{ objectFit: "cover" }}
              src="https://bandland.s3.ap-south-1.amazonaws.com/images/redBand.png"
              alt="img"
              width="100%"
              height={
                windowWidth <= 1024
                  ? 500
                  : windowWidth <= 1280
                  ? 430
                  : windowWidth <= 1440
                  ? 470
                  : windowWidth <= 1280
                  ? 500
                  : 550
              }
            />
          ) : (
            <img
              style={{ objectFit: "cover" }}
              src="https://bandland.s3.ap-south-1.amazonaws.com/images/redBand.png"
              alt="img"
              width="100%"
              height={480}
            />
          )}
        </Box>
        <Box position="absolute" top="0" width="100%">
          <Typography
            sx={{
              mt: { xs: 2, lg: 2 },
              fontSize: {
                xs: "35px",
                sm: "50px",
                md: "65px",
                lg: "80px",
                xl: "90px",
              },
            }}
            fontFamily="modesto-condensed"
            color="white"
            style={{ textShadow: "3px 3px 0px #390c06" }}
            // className="font-modesto-condensed text-shadow heading-relative z-index-2"
          >
            BE A CITIZEN OF {windowWidth <= 600 && <br />} BANDLAND!
          </Typography>
          <Box
            // sx={{ mt: { xs: 2, lg: 10 } }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className="pos-relative flex-center flex-col align-center"
            // marginBottom="10%"
          >
            <img
              // className="about-responsive-text-banner"
              width={"60%"}
              height={
                windowWidth <= 600
                  ? 300
                  : windowWidth <= 650
                  ? 330
                  : windowWidth <= 1024
                  ? 300
                  : windowWidth <= 1280
                  ? 270
                  : windowWidth <= 1440
                  ? 320
                  : windowWidth <= 1680
                  ? 360
                  : 360
              }
              src="https://bandland.s3.ap-south-1.amazonaws.com/images/Home/Text+Banner.png"
              alt="img"
            />
            <Box
              fontFamily="texturina"
              position="absolute"
              width="45%"
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "16px",
                  md: "18px",
                  lg: "20px",
                  xl: "24px",
                  xll: "26px",
                },
              }}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              marginTop="-20px"
            >
              <Typography
                fontFamily="texturina"
                width="100%"
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "16px",
                    md: "18px",
                    lg: "20px",
                    xl: "24px",
                    xll: "28px",
                  },
                }}
                className=" bannerText font-texturina z-index-3"
              >
                <strong>
                  Get updates on all things for the upcoming edition here!
                </strong>
                <br />
                Join the inner circle to receive all festival updates, from
                early bird tickets, artist lineup & more.
              </Typography>
              <Button
                variant="contained"
                // fontFamily="modesto-condensed"
                // backgroundColor="#44c0a8"
                style={{
                  fontFamily: "modesto-condensed",
                  backgroundColor: "#44c0a8",
                  cursor: "pointer",
                  zIndex: 4,
                }}
                // className="font-modesto-condensed bg-color z-index-3"
                sx={{
                  mt: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
                  fontSize: { sm: "20px", lg: "28px" },
                }}
                onClick={() => navigate("/register")}
              >
                {" "}
                Sign Up{" "}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box position="relative" bottom="0">
        <Footer />
      </Box>
    </Box>
  );
  return (
    <Box>
      <Helmet>
        <title>Bandland 2023 | About Us</title>
        <meta
          name="description"
          content="Get Contact Details & Customer Service for all information for Bandland Bangalore 2023."
        />
        {/* <meta name="keywords" content="Bandland, Bandland 2023, Bandland concert, Bandland party, Bandland Bangalore, Bandland tickets, Bandland 2023, Bandland Bangalore tickets" /> */}
      </Helmet>
      <Header pageContent={pageContent} />
    </Box>
  );
};

export default Home;
