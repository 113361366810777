import React from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Close,
  Facebook,
  Instagram,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import "./index.css";
// import WhiteLogo from "../../images/Bandland Logo White.svg";
import { Box, Button, Grid } from "@mui/material";
// import Stars from "../../images/Home/Stars.png";
// import Foot from "../../images/Home/Foot.png";
import { Link as RouteLink } from "react-router-dom";
import { Link } from "@mui/material";

const HamburgerButton = (props) => {

  const { handleDrawerToggle } = props;
  
  return (
    <div className="pos-relative flex-center flex-col align-center">
      {/* <img
        className="pos-fixed top-0 rotate"
        src={Foot}
        style={{ width: "100%" }}
      /> */}

      <IconButton sx={{ mt: 2 }} onClick={handleDrawerToggle}>
        <Close fontSize="large" sx={{ color: "#fffde7" }} />
      </IconButton>
      <Grid container spacing={2}>
        <Grid item xs={12} className="flex-center align-center">
          <img
            src="https://bandland.s3.ap-south-1.amazonaws.com/images/Bandland+Logo+White.svg"
            className="rotate"
            width={"70%"}
            style={{ marginTop: 20 }}
            alt="img"
          />
        </Grid>
        <Grid item xs={12} className="flex-center align-center">
          <Link to={"/"} component={RouteLink}>
            <Button
              href="/"
              className="font-modesto-condensed btn-text"
              sx={{
                fontSize: 40,
                // fontSize: 50,
                color: "#fffde7 !important",
              }}
            >
              HOME
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} className="flex-center align-center"
          // remove marginBottom and uncomment button size for 2nd phase
          // marginBottom="50px"
        >
          <Link to={"/register"} component={RouteLink}>
            <Button
              className="font-modesto-condensed btn-text"
              sx={{
                fontSize: 40,
                // fontSize: 50,
                color: "#fffde7 !important",
                mt: -5,
              }}
            >
              REGISTER
            </Button>
          </Link>
        </Grid>
        {/* For sencod phase */}
        <Grid item xs={12} className="flex-center align-center">
          <Link to={"/about"} component={RouteLink}>
            <Button
              className="font-modesto-condensed btn-text"
              sx={{
                fontSize: 40,
                color: "#fffde7 !important",
                mt: -5,
              }}
            >
              ABOUT
            </Button>
          </Link>
        </Grid>
        {/* <Grid item xs={12} className="flex-center align-center">
          <Link to={"/the-artists"} component={RouteLink}>
            <Button
              className="font-modesto-condensed btn-text"
              sx={{
                fontSize: 40,
                color: "#fffde7 !important",
                mt: -5,
              }}
            >
              THE ARTISTS
            </Button>
          </Link>
        </Grid> */}
        {/* <Grid item xs={12} className="flex-center align-center">
          <Link to={"/tickets"} component={RouteLink}>
            <Button
              className="font-modesto-condensed btn-text"
              sx={{
                fontSize: 40,
                color: "#fffde7 !important",
                mt: -5,
              }}
            >
              TICKETS
            </Button>
          </Link>
        </Grid> */}
        {/* <Grid item xs={12} className="flex-center align-center"> */}
          {/* <Link to={"/faq"} component={RouteLink}> */}
          {/* <Button
            className="font-modesto-condensed btn-text"
            sx={{
              fontSize: 40,
              color: "#fffde7 !important",
              mt: -5,
            }}
            onClick={() => window.open("https://drive.google.com/file/d/1YadDQe-Qe1pKsUEE2hyH-o8lfhhJihMw/view?usp=drive_link")}
          >
            STAYCATIONS
          </Button> */}
          {/* </Link> */}
        {/* </Grid> */}
        {/* <Grid item xs={12} className="flex-center align-center">
          <Link to={"/faq"} component={RouteLink}>
            <Button
              className="font-modesto-condensed btn-text"
              sx={{
                fontSize: 40,
                color: "#fffde7 !important",
                mt: -5,
              }}
            >
              FAQ
            </Button>
          </Link>
        </Grid> */}
        
        <Grid item xs={12} className="flex-center align-center">
          <Box className="flex-center z-index-2">
            <IconButton>
              <Instagram fontSize="large" sx={{ color: "#fffde7" }} onClick={() => window.open("https://www.instagram.com/bandland.in/")} />
            </IconButton>
            <IconButton>
              <Facebook fontSize="large" sx={{ color: "#fffde7" }} onClick={() => window.open("https://www.facebook.com/profile.php?id=61551815635302&mibextid=LQQJ4d")} />
            </IconButton>
            <IconButton>
              <Twitter fontSize="large" sx={{ color: "#fffde7" }} onClick={() => window.open("https://twitter.com/BandlandIndia")} />
            </IconButton>
            <IconButton>
              <YouTube fontSize="large" sx={{ color: "#fffde7" }} onClick={() => window.open("https://youtu.be/2LPcFcErbhk")} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      {/* <img
        className="pos-fixed z-index-1"
        src={Stars}
        style={{ width: "150%", bottom: "-10%" }}
        alt="img"
      /> */}
    </div>
  );
};

export default HamburgerButton;
