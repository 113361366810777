import React, { useEffect, useState } from "react";
// import Stars from "../../images/Home/Stars.png";
// import Foot from "../../images/Home/Foot.png";
import { Bolt } from "@mui/icons-material";
// import WhiteLogo from "../../images/Bandland Logo White.svg";
// import BMSLiveSvg from "../../images/BMS LIVE.svg";
// import "../../styles/Home.css";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
// import FooterBase from "../../images/Home/Footer.png";
// import MobileFooterBase from "../../images/Home/SmallFooter.png";
import "./footer.css";
import { Link as RouteLink } from "react-router-dom";
import { Link } from "@mui/material";
import theme from "../../theme";
import IconButton from "@mui/material/IconButton";
import {
  Close,
  Facebook,
  Instagram,
  Twitter,
  YouTube,
} from "@mui/icons-material";

const Footer = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  // Function to update the screen height
  const updateScreenHeight = () => {
    setScreenHeight(window.innerHeight);
    console.log('window:', window.innerHeight)
  };

  // Calculate the screen height initially and on window resize
  useEffect(() => {
    updateScreenHeight();
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  return (
    <Box
      className="pos-relative flex-center flex-col align-center  bottom-0 "
      // position="relative" display="flex" justifyContent="center" flexDirection='column' alignItems="center" bottom="0"
      // border="2px solid red"
      // style={{ width: "100vw" }}
    >
      {/* <img
        className="pos-absolute bottom-0 z-index-1"
        src={Foot}
        style={{ width: "90%" }}
        alt="img"
      /> */}
      {isSmallScreen ? <img
        className="pos-absolute bottom-2 z-index-1"
        
        src="https://bandland.s3.ap-south-1.amazonaws.com/images/Home/Stars.png"
        style={{ width: "100%", position: 'absolute', bottom: 2, zIndex: 1 }}
        alt="img"
        loading="lazy"
      /> : <></>}
      <Box position="relative" zIndex="4" bottom="0" className="pos-relative z-index-4 bottom-0 ">
        {isSmallScreen ? <Box display="flex" justifyContent="center" overflow="hidden"  sx={{ height: { xs: "39vh", sm: screenHeight<600? '150vh': "42vh", md: screenHeight <= 800 ? "75vh" : "55vh", lg: screenHeight <= 800 ? "85vh" : "67vh", xl: "80vh", xll: screenHeight <= 900 ? "120vh":screenHeight <= 1100 ? "96vh":"75vh" } }} >
          <img
            src="https://bandland.s3.ap-south-1.amazonaws.com/images/Home/Footer.png"
            // className="footer mobileFooterHeight"
          width={"100%"}
          height="100%"
            style={{ marginBottom: "-5px" }}
            alt="img"
            loading="lazy"
        /> </Box> : <Box height={550} display="flex" justifyContent="center" overflow="hidden">
            {/* For Second Phase <Box height="85vh" display="flex" justifyContent="center" overflow="hidden"> */}
          <img
              src="https://bandland.s3.ap-south-1.amazonaws.com/mobile/SmallFooter.webp"
            className="footer FooterHeight"
            width={"145%"}
            height="100%"
            style={{ objectFit: "fill" }}
              alt="img"
              loading="lazy"
          />
        </Box>
          }
        <Box
          position="absolute" zIndex="4" display="flex" flexDirection="column" alignItems="center"
          className="pos-absolute  z-index-4 flex flex-col align-center"
          width={"100%"}
          sx={{ top: { xs: "30%", sm: "30%", md: "30%", lg: "30%", xl: "30%" } }}
        >
          <img src="https://bandland.s3.ap-south-1.amazonaws.com/images/Bandland+Logo+White.svg" className="rotate whiteLogoImg" alt="img" />
          
          <Box >
            <IconButton>
              <Instagram sx={{ color: "black", fontSize: { xs: "30px", sm: "30px", md: "35px", lg: "45px", xl: "50px", xll: "60px" } }} onClick={() => window.open("https://www.instagram.com/bandland.in/")} />
            </IconButton>
            <IconButton>
              <Facebook sx={{ color: "black", fontSize: { xs: "30px", sm: "30px", md: "35px", lg: "45px", xl: "50px", xll: "60px" } }} onClick={() => window.open("https://www.facebook.com/profile.php?id=61551815635302&mibextid=LQQJ4d")} />
            </IconButton>
            <IconButton>
              <Twitter sx={{ color: "black", fontSize: { xs: "30px", sm: "30px", md: "35px", lg: "45px", xl: "50px", xll: "60px" } }} onClick={() => window.open("https://twitter.com/BandlandIndia")} />
            </IconButton>
            <IconButton>
              <YouTube sx={{ color: "black", fontSize: { xs: "30px", sm: "30px", md: "35px", lg: "45px", xl: "50px", xll: "60px" } }} onClick={() => window.open("https://youtu.be/2LPcFcErbhk")} />
            </IconButton>
          </Box>

          <Box
            display="flex" alignItems="center"
            sx={{ flexDirection: { xs: "column", sm: "row" }, gap: { xs: "20px", sm: "5px", lg: "20px", xl: "20px", xll: "20px" } }}
            className="flex align-center "
          >
            
            <Link to={"/"} component={RouteLink}>
              <Button
                className="font-modesto-condensed btn-text"
                sx={{
                  px: 0,
                  mt: { xs: -1, sm: 0 },
                  fontSize: { xs: "25px", sm: "20px", md: "25px", lg:"35px", xl:"40px", xll:"50px",xxl:"60px"},
                  fontFamily: 'modesto-condensed',
                  color: 'black',
                }}
              >
                HOME
              </Button>
            </Link>
            <Bolt
              sx={{ px: 0, display: { xs: "none", sm: "block" },fontSize: { xs: "25px", sm: "20px", md: "25px", lg:"35px", xl:"40px", xll:"50px",xxl:"60px"}, }}
              fontSize="large"
            />
            <Link to={"/register"} component={RouteLink}>
              <Button
                className="font-modesto-condensed btn-text"
                sx={{
                  mt: { xs: -5, sm: 0 },
                  fontSize: { xs: "25px", sm: "20px", md: "25px", lg:"35px", xl:"40px", xll:"50px",xxl:"60px"},
                  fontFamily: 'modesto-condensed',
                  color: 'black',
                }}
              >
                REGISTER
              </Button>
            </Link>
            {/* For sencod phase */}
            <Bolt
              sx={{ px: 0, display: { xs: "none", sm: "block" },fontSize: { xs: "25px", sm: "20px", md: "25px", lg:"35px", xl:"40px", xll:"50px",xxl:"60px"}, }}
              fontSize="large"
            />
            <Link
              to={"/about"}
              component={RouteLink}
              // sx={{ display: { xs: "none", sm: "block" } }}
            >
              <Button
                className="font-modesto-condensed btn-text"
                sx={{
                  mt: { xs: -6, sm: 0 },
                  fontSize: { xs: "25px", sm: "20px", md: "25px", lg:"35px", xl:"40px", xll:"50px",xxl:"60px"},
                  fontFamily: 'modesto-condensed',
                  color: 'black',
                }}
              >
                ABOUT
              </Button>
            </Link>
            {/* <Bolt
              sx={{ px: 0, display: { xs: "none", sm: "block" },fontSize: { xs: "25px", sm: "20px", md: "25px", lg:"35px", xl:"40px", xll:"50px",xxl:"60px"}, }}
              fontSize="large"
            />
            <Link
              to={"/the-artists"}
              component={RouteLink}
              // sx={{ display: { xs: "none", sm: "block" } }}
            >
              <Button
                className="font-modesto-condensed btn-text"
                sx={{
                  mt: { xs: -7, sm: 0 },
                  fontSize: { xs: "25px", sm: "20px", md: "25px", lg:"35px", xl:"40px", xll:"50px",xxl:"60px"},
                  fontFamily: 'modesto-condensed',
                  color: 'black',
                }}
              >
                THE ARTISTS
              </Button>
            </Link>
            <Bolt
              sx={{ px: 0, display: { xs: "none", sm: "block" },fontSize: { xs: "25px", sm: "20px", md: "25px", lg:"35px", xl:"40px", xll:"50px",xxl:"60px"}, }}
              fontSize="large"
            /> */}
            {/* <Link
              to={"/tickets"}
              component={RouteLink}
              // sx={{ display: { xs: "none", sm: "block" } }}
            >
              <Button
                className="font-modesto-condensed btn-text"
                sx={{
                  mt: { xs: -7, sm: 0 },
                  fontSize: { xs: "25px", sm: "20px", md: "25px", lg:"35px", xl:"40px", xll:"50px",xxl:"60px"},
                  fontFamily: 'modesto-condensed',
                  color: 'black',
                }}
              >
                TICKETS
              </Button>
            </Link> */}
            {/* <Bolt
              sx={{ px: 0, display: { xs: "none", sm: "block" }, fontSize: { xs: "25px", sm: "20px", md: "25px", lg: "35px", xl: "40px", xll: "50px", xxl: "60px" }, }}
              fontSize="large"
            /> */}
            {/* <Link
              to={"/faq"}
              component={RouteLink}
              // sx={{ display: { xs: "none", sm: "block" } }}
            > */}
            {/* <Button
              className="font-modesto-condensed btn-text"
              sx={{
                mt: { xs: -6, sm: 0 },
                fontSize: { xs: "25px", sm: "20px", md: "25px", lg: "35px", xl: "40px", xll: "50px", xxl: "60px" },
                fontFamily: 'modesto-condensed',
                color: 'black',
              }}
              onClick={() => window.open("https://drive.google.com/file/d/1YadDQe-Qe1pKsUEE2hyH-o8lfhhJihMw/view?usp=drive_link")}
            >
              STAYCATIONS
            </Button> */}
            {/* </Link> */}
            {/* <Bolt
              sx={{ px: 0, display: { xs: "none", sm: "block" },fontSize: { xs: "25px", sm: "20px", md: "25px", lg:"35px", xl:"40px", xll:"50px",xxl:"60px"}, }}
              fontSize="large"
            /> */}
            {/* <Link
              to={"/faq"}
              component={RouteLink}
              // sx={{ display: { xs: "none", sm: "block" } }}
            >
              <Button
                className="font-modesto-condensed btn-text"
                sx={{
                  mt: { xs: -7, sm: 0 },
                  fontSize: { xs: "25px", sm: "20px", md: "25px", lg:"35px", xl:"40px", xll:"50px",xxl:"60px"},
                  fontFamily: 'modesto-condensed',
                  color: 'black',
                }}
              >
                FAQ
              </Button>
            </Link> */}
            
          </Box>
          <img
            src="https://bandland.s3.ap-south-1.amazonaws.com/artists/BMS+produced+promoted.svg"
            className="rotate live-logo footerBGImg"
            alt="img"
          />
          <Box sx={{ mt: { xs: 0, sm: 2 } }}>
            <Typography
              variant="caption"
              className="text-texturina"
              sx={{ fontSize: { xs: 10, sm: 12, md: 16, lg: 18, xl: 20, xll: 22 } }}
            >
              © | <a href="https://drive.google.com/file/d/1sh4XL3gvfAlXmq5agY8BjQ4pOG8YUTef/view?usp=sharing" className="privacyTag">Privacy</a> | <a href="https://drive.google.com/file/d/1gIPDMCcqxn7fZI2YxdpHf8DZx6Zqrmgc/view?usp=sharing" className="privacyTag">Terms & Conditions</a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
